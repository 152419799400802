import {Config} from "./models/config";

export class CONF {
    /**
     * Custom configuration for this app.
     * This will overwrite the default values in CoreConfig if needed.
     *
     * @type {Config}
     */
    public static config = {
        auditLog: true,
        instance: 'mauricioFranklinPtT',
        analyticTrackerId: 'UA-52740322-11',
        googleStaticMapApi: 'AIzaSyCOB3EQ5Q6_1PQdbJt23kzotaFoHou5hPQ',
        debug: 1,
        defaultLanguage: 'nl',
        name: 'Mauricio Franklin PT',
        version: '1.0.0',

        authenticationPersistenceState: 'local',
        isGenericVersion: false,
        isOnlineVersion: true,
        toastDuration: 3,
    } as Config;

    public static firebaseConfig = {
        apiKey: "AIzaSyBTs1De2jvtPKH_Am9bt935r0kSI8QHFqE",
        authDomain: "mauriciofranklinpt.firebaseapp.com",
        databaseURL: "https://mauriciofranklinpt.firebaseio.com",
        projectId: "mauriciofranklinpt",
        storageBucket: "mauriciofranklinpt.appspot.com",
        messagingSenderId: "783677909751"
    };
}
