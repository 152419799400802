import {Injectable} from '@angular/core';
import {Events, Config} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {CoreUserProvider} from '../../core/services/coreUser';
import {ItemClass} from '../../core/models/abstractItem';
import {User} from '../models/user.model';
import {DatabaseProvider} from './database';
import {ConfigProvider} from './config';

/**
 * User Provider how to use
 * From other pages/services just change settings to the database, the observable will process it here in 'updateUser()'
 * e.g. new notification/follower, ...
 *
 * Todo SoftDelete Account?
 */
@Injectable()
export class UserProvider extends CoreUserProvider implements ItemClass {

    public item = {
        'lang': this.config.config.defaultLanguage
    } as User;         //set by the child page init

    branchItem = 'user';


    constructor(public database: DatabaseProvider,
                public config: ConfigProvider,
                public events: Events,
                public translate: TranslateService,
                public ionicConfig: Config) {

        super(config, database);

    }

}
