import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {AuthenticationProvider} from 'src/custom/services/authentication';
import {ConfigProvider} from "..";


@Injectable({
    providedIn: 'root',
})
export class CanActivateGuard implements CanActivate {

    constructor(private auth: AuthenticationProvider,
                private router: Router,
                private config: ConfigProvider) {
    }


    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        const url: string = state.url;

        return this.checkLogin(url);
    }

    checkLogin(url: string): boolean {
        // The user is not authenticated OR the app is still processing/checking the auth state.
        // Afterwhich the app will navigate the user to the home page. Which is desired.
        if (this.auth.isAuthenticated) {
            return true;
        }

        // Store the attempted URL for redirecting
        // this.authService.redirectUrl = url;

        // Navigate to the login page with extras
        this.router.navigate(['/home']);
        return false;
    }
}
