import {Injectable} from '@angular/core';
import {Config, Events, Platform} from '@ionic/angular';
import {AngularFireAuth} from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import {ReplaySubject} from "rxjs/ReplaySubject";
import {Subject} from "rxjs/Subject";
import {AbstractItem, ItemClass} from "../models/abstractItem";
import {CoreConfigProvider} from "./coreConfig";
import {CoreDatabaseProvider} from "./coreDatabase";
import {CoreAnalyticsProvider} from "./coreAnalytics";
import {CoreUser} from "../models/coreUser";
import {TranslateService} from "@ngx-translate/core";
import * as moment from 'moment';
import {CoreUserProvider} from "./coreUser";

/**
 * Should always be inherited in custom code. So it inherits all providers/settings correctly.
 *
 * States:
 * 1. Authenticated = authenticated with the backend
 * 2. Login = is log'd in in the instance (first needs to be authenticated).
 *
 * Authentication Provider for Firebase login
 * Documentation: https://firebase.google.com/docs/reference/js/firebase.auth.Auth
 *
 * Documentation on how to implement @angular/fire in ionic3:
 * https://github.com/angular/@angular/fire/blob/master/docs/ionic/v3.md
 *
 * Todo
 */
@Injectable({providedIn: 'root'})
export abstract class CoreAuthenticationProvider {

    /*
     * Simple event/Subject emittors to help handling events
     * Use this.authentication.login.subscribe();
     */

    // User passed firebase authentication but isn't yet verified as a business club member
    public authenticated: ReplaySubject<any> = new ReplaySubject(1); //ReplaySubject because it emits last value on subscribe.

    // User passed authentication AND is user of instance.
    public login: ReplaySubject<AbstractItem> = new ReplaySubject(1); //ReplaySubject because it emits last value on subscribe.

    // Emitter when user log's out.
    public logout: ReplaySubject<any> = new ReplaySubject(1);

    // Emitter when the user is authenticated for the firsttime
    public newUser: Subject<any> = new Subject;

    // Emitter for current User
    //public currentAuthenticatedUserSubject: ReplaySubject<any> = new ReplaySubject(1);

    // Using currentAuthenticatedUser in Config as we import here CoreUser, which would make a circular dependancy.
    public currentAuthenticatedUser: CoreUser;


    public uid; // Store user Id to check for refresh tokens

    public multipleInstancesPage: string;

    public isAuthenticated: boolean = false;
    public isLoggedIn: boolean = false;

    public instanceList = 'instances';

    constructor(public config: CoreConfigProvider,
                public afAuth: AngularFireAuth,
                // private facebook: Facebook,
                public translate: TranslateService,
                public platform: Platform,
                public ionicConfig: Config,
                public analyticsProvider: CoreAnalyticsProvider,
                public database: CoreDatabaseProvider,
                public coreUser: CoreUserProvider,
                public events: Events,
                //public app: App,
    ) {

        this.destroyAuthentication();
        this.initialisation();

    }

    /**
     * Start listening to authentication state changes.
     */
    initialisation() {
        // this.afAuth.auth.languageCode(this.config.config.defaultLanguage);
        this.setPersistance();

        // *** Listen to authentication changes
        this.config.debugLog("Listening to auth state");
        this.afAuth.authState.subscribe(this.processAuthStateChanged.bind(this));

        // *** User is authenticated, process it
        this.authenticated.subscribe(this.processAuthenticated.bind(this));

        // *** User is log'd in
        this.login.subscribe(this.processLogin.bind(this));

        // *** User is log'd out
        this.logout.subscribe(this.processLogout.bind(this));
    }

    /**
     * auth state has changed, process it
     * @param afUser
     */
    processAuthStateChanged(afUser) {
        if (!afUser) {
            //Logout
            this.config.debugLog("Logout");
            this.logout.next({});

            // return;
        }
        else {
            // If this is just an ID token refresh, we exit and don't process further.
            if (this.uid && this.uid === afUser.uid) {
                return;
            }
            //Login
            this.config.debugLog("New login State Change", afUser);

            //Set userId in Analytics for better reports
            this.uid = afUser.uid;
            this.analyticsProvider.setUserId(this.uid);

            //Fire authentication observer (to start logging in the user.)
            this.authenticated.next(afUser);
        }
    }

    /**
     * User authentication received, process it.
     * @param afUser
     */
    processAuthenticated(afUser) {

        this.isAuthenticated = true;

        //get User from database with corresponding uid
        this.coreUser.getCoreUser(afUser.uid).subscribe((user: CoreUser) => {

            // No user data found in db = meaning new user
            if (!user) {
                this.config.debugLog("New user (not yet in db) " + this.uid);
                this.newLoginUser();
            }
            else {
                this.config.debugLog("Auth: Got Core user from db " + this.uid, user);

                //Check if it is a new user
                /*
                if (!user.createdAt) {
                    this.newLoginUser();
                }
                else { */

                // Could be this is not a login, but just a new event from the user subscription.
                if ((this.currentAuthenticatedUser)
                    && (this.currentAuthenticatedUser.id === this.uid)) {
                    // User is already log'd in, just a new user entry.
                    this.config.debugLog("Auth: user was already log'd in, updating user details")
                    this.setCurrentUser(user);
                }
                else {
                    // New login
                    this.config.debugLog("Auth: new user login", user);
                    this.setCurrentUser(user);

                    //If this is the generic version of the app. Check if the user is in multiple instances
                    if (this.config.config.isGenericVersion) {
                        this.config.debugLog("This is a generic app, checking the instances the user has access to.");
                        if (user[this.instanceList]) {
                            let amountOfInstancesUserHasAccessTo = Object.keys(user[this.instanceList]).length;
                            this.config.debugLog("User has access to " + amountOfInstancesUserHasAccessTo + " instances", user);

                            // If the user has only access to 1 instance, log him in into that instance without prompting
                            if (amountOfInstancesUserHasAccessTo == 1) {
                                this.config.debugLog("As the user has only access to 1 instance, redirect him to this instance");
                                let instance = Object.keys(user[this.instanceList])[0];
                                this.selectInstance(instance);
                            }
                            else {
                                let instances = [];
                                for (let instance in user[this.instanceList]) {
                                    instances.push(instance);
                                }
                                //The user has access to multiple instances, show a list on which the user can chose.
                                if (!this.multipleInstancesPage) {
                                    this.config.debugLog("Error: The user has access to multiple instances, yet no multipleInstancesPage was found to guide the user to. Don't forget to set it in authenticationProvider.");
                                }
                                else {
                                    // TODO
                                    // DEPRECATED
                                    // let nav = this.app.getActiveNav();
                                    //nav.setRoot(this.multipleInstancesPage, {instances: instances});
                                }
                            }
                        }
                        else {
                            this.config.debugLog("User is authenticad but doesn't have access to any instance? hmmm strange");
                        }
                    }
                    else {
                        // No generic version, just continue with current instance.
                        this.selectInstance(this.config.config.instance || null);
                    }
                }
            }

        });
    }

    /**
     * User is log'd in, process it
     * @param user
     */
    processLogin(user) {
        // Update lastLogin timestamp
        this.currentAuthenticatedUser.lastLogin = CoreDatabaseProvider.timestamp();

        /*if (this.config.config.isOnlineVersion) {
            userObject['lastOnlineBC'] = this.config.config.instance;
        }*/

        // Update user in db
        this.coreUser.updateCoreUser(this.currentAuthenticatedUser);
    }

    /**
     * User log'd out or timed out, process it.
     */
    processLogout() {
        this.isLoggedIn = false;
        this.isAuthenticated = false;
        this.destroyAuthentication();
    }

    /**
     * Set current user
     */
    setCurrentUser(user: CoreUser) {
        let processedUser = this.processLoginUser(user);
        if (user.rights) {
            this.config.authUserRights = user.rights;
        }
        this.config.currentAuthenticatedUser = processedUser;
        this.currentAuthenticatedUser = processedUser;

    }

    /**
     * Get Current User
     */
    getCurrentUser() {
        return this.currentAuthenticatedUser;
    }

    /**
     * Select an instance to connect to.
     * @param {string} instanceName
     */
    selectInstance(instanceName: string = null) {
        if (instanceName) this.config.config.instance = instanceName;

        // Core Auth has finished processing the authentication,
        this.extendLogin(instanceName, this.currentAuthenticatedUser).then((authenticatedUser) => {
            this.login.next(authenticatedUser);
        }).catch((error) => {
            //error occured during extendLogin, don't login
            //this.login.next();

            this.destroyAuthentication();
        });
    }

    // Parent placeholder function that can be overwritten by child.
    extendLogin(instanceName: string, authenticatedUser: CoreUser) {
        return Promise.resolve(authenticatedUser);
    }

    /**
     * User is authenticated, but has no data yet in the db (so a new user)
     */
    newLoginUser() {
        let user = {};
        user['id'] = this.uid;
        user['createdAt'] = CoreDatabaseProvider.timestamp();
        user['lang'] = this.config.config.defaultLanguage;
        // Fix add user['mail']
        //if (this.config.config.isOnlineVersion) { user['lastOnlineBC'] = this.config.config.instance; }

        this.coreUser.updateCoreUser(user);
        this.config.debugLog("New user", user);

        this.events.publish('user:new', user);
    }

    /**
     * Process Login and set all the environment variables for the user (e.g. lang, ...)
     * Function can be extended in the child provider with extendProcessLogin
     *
     * @param {CoreUser} user
     * @returns {CoreUser}
     */
    processLoginUser(user: CoreUser) {
        // Set language
        if (user.lang) {
            this.translate.use(user.lang);
            moment.locale(user.lang);
            this.translate.get(['BACK_BUTTON_TEXT']).subscribe(values => {
                // DEPRECATED IONIC4 this.ionicConfig.set('ios', 'backButtonText', values.BACK_BUTTON_TEXT);
                this.ionicConfig.set('backButtonText', values.BACK_BUTTON_TEXT);
            });
        }
        else {
            this.translate.use(this.config.config.defaultLanguage);
        }

        //Set default BC
        //console.log(this.config.config.instance);
        //if ((this.config.config.isOnlineVersion) && (this.config.config.instance == "onlineKn-app")) { this.config.config.activeBusinessClub = this.user.lastOnlineBC; }

        this.extendProcessLogin(user);

        return user;
    }

    /**
     * Function to extend the process Login function in the child (for extra processing if needed).
     *
     * @param item
     */
    extendProcessLogin(user: CoreUser) {
        return user;
    };

    /**
     * Destroy Authentication (triggered after the user has been de-authenticated)
     */
    destroyAuthentication() {
        this.config.debugLog("Destroying user");

        //this.isLoggedIn = false;
        //delete this.config.currentAuthenticatedUser; // Commented as it gives errors on settingspage of bc-app
        if (this.config.config.isGenericVersion) delete this.config.config.instance;
        delete this.uid;
        //delete this.business;

        /*if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.businessSubscription) {
            this.businessSubscription.unsubscribe();
        }*/
    }

    /**
     * Function triggered on sign-in or sign-out
     *
     * @returns {firebase.Unsubscribe}
     */
    onAuthStateChanged(callback) {
        //Same as ? return this.afAuth.authstate;
        return this.afAuth.auth.onAuthStateChanged(callback);
    }

    /**
     * Login the user with email and password credentials
     *
     * @param mail
     * @param {string} password
     * @returns {Promise<any>}
     */
    loginUser(mail: string, password: string): Promise<any> {
        this.analyticsProvider.logEvent('authentication', 'loginUser', 'mail', mail);
        return this.afAuth.auth.signInWithEmailAndPassword(mail, password);
    }

    /**
     * Sign in as an anonymous user
     * If there is already an anonymous user signed in, that user will be returned;
     *  otherwise, a new anonymous user identity will be created and returned.
     *
     * @returns {Promise<any>}
     */
    signInAnonymously(): Promise<any> {
        this.analyticsProvider.logEvent('authentication', 'loginAnonymous');
        return this.afAuth.auth.signInAnonymously();
    }

    /**
     * Asynchronously signs in with the given credentials.
     *
     * @param credential
     * @returns {Promise<any>}
     */
    signInAndRetrieveDataWithCredential(credential: any) { //Todo Typing should be AuthCredential
        return this.afAuth.auth.signInAndRetrieveDataWithCredential(credential);
    }

    /**
     * PLACEHOLDER FOR IN CUSTOM CODE, never change core code with custom code.
     *
     * Sign in With Facebook with popup in browser or plugin in an app
     *
     *
     * Install:
     *  npm install --save @ionic-native/facebook
     *  ionic cordova plugin add cordova-plugin-facebook4 --variable APP_ID="123456789" --variable APP_NAME="myApplication"
     * Tutorial: https://github.com/angular/@angular/fire/blob/master/docs/ionic/v3.md
     *
     * @returns {Promise<void>}
     */
    signInWithFacebook() {
        this.analyticsProvider.logEvent('authentication', 'loginFacebook');
        if (this.platform.is('cordova')) {
            /*
             Uncomment these lines in custom code (not CORE!) when you have installed Facebook (see above comments how to install)
             return this.facebook.login(['email', 'public_profile']).then(res => {
                 const credential = firebase.auth.FacebookAuthProvider.credential(res.authResponse.accessToken);
                 //return firebase.auth().signInWithCredential(facebookCredential);
                 return this.signInWithCredential(credential);
             });
             */
        }
        else {
            return this.afAuth.auth
                .signInWithPopup(new firebase.auth.FacebookAuthProvider())
                .then(res => console.log(res));
        }
    }

    /**
     * Sign in With Google with popup in browser or plugin in an app
     * Install:
     *  Todo
     *
     * @returns {Promise<void>}
     */
    signInWithGoogle() {
        this.analyticsProvider.logEvent('authentication', 'loginGoogle');
        if (this.platform.is('cordova')) {
            /*
             Uncomment these lines when you have installed Facebook (see above comments how to install)
             return this.google.login(BLABLA).then(res => {
                 const credential = firebase.auth.GoogleAuthProvider.credential(res.authResponse.accessToken);
                 return this.signInWithCredential(credential);
             });
             */
        }
        else {
            return this.afAuth.auth
                .signInWithPopup(new firebase.auth.GoogleAuthProvider())
                .then(res => console.log(res));
        }
    }


    /**
     * Sign in With Twitter with popup in browser or plugin in an app
     * Install:
     *  Todo
     *
     * @returns {Promise<void>}
     */
    signInWithTwitter() {
        this.analyticsProvider.logEvent('authentication', 'loginTwitter');
        if (this.platform.is('cordova')) {
            /*
             Uncomment these lines when you have installed Facebook (see above comments how to install)
             return this.twitter.login(BLABLA).then(res => {
                 const credential = firebase.auth.TwitterAuthProvider.credential(res.authResponse.accessToken);
                 return this.signInWithCredential(credential);
             });
             */
        }
        else {
            return this.afAuth.auth
                .signInWithPopup(new firebase.auth.TwitterAuthProvider())
                .then(res => console.log(res));
        }
    }


    /**
     * Sign in With Github with popup in browser or plugin in an app
     * Install:
     *  Todo
     *
     * @returns {Promise<void>}
     */
    signInWithGithub() {
        this.analyticsProvider.logEvent('authentication', 'loginGithub');
        if (this.platform.is('cordova')) {
            /*
             Uncomment these lines when you have installed Facebook (see above comments how to install)
             return this.Github.login(BLABLA).then(res => {
                 const credential = firebase.auth.GithubAuthProvider.credential(res.authResponse.accessToken);
                 return this.signInWithCredential(credential);
             });
             */
        }
        else {
            return this.afAuth.auth
                .signInWithPopup(new firebase.auth.GithubAuthProvider())
                .then(res => console.log(res));
        }
    }

    /**
     *
     */
    signInWithPhone(phoneNumber: string, appVerifier: any) {
        let phoneNumberString = "+" + phoneNumber; // Todo make smart
        return firebase.auth().signInWithPhoneNumber(phoneNumberString, appVerifier);
    }

    /**
     * signOut
     *
     * @returns {Promise<any>}
     */
    signOut() {
        this.database.destroyAllSubscriptions().then((result) => {
            return this.signoutAuth();
        }).catch((error) => {
            this.config.debugLog("Encountered an error while destroying all active subscriptions, still continueing to log out.", error);
            return this.signoutAuth();
        })
    }

    /**
     * signout of the authentication
     * @returns {Promise<any>}
     */
    signoutAuth() {
        this.config.debugLog("Signing out");
        this.analyticsProvider.logEvent('authentication', 'signOut');
        if (this.config.config.isGenericVersion) {
            // Load default config (to reset name)
            this.config.loadConfig();
        }
        return this.afAuth.auth.signOut();
    }

    /**
     * Create New User with email and password credentials
     *
     * @param {string} email
     * @param {string} password
     * @returns {Promise<any>}
     */
    createUserWithEmailAndPassword(mail: string, password: string) {
        this.analyticsProvider.logEvent('authentication', 'createUser', 'mail', mail);
        return this.afAuth.auth.createUserWithEmailAndPassword(mail, password);
    }

    /**
     * Gets the list of provider IDs that can be used to sign in for the given email address.
     * Useful for an "identifier-first" sign-in flow.
     *
     * @param {string} email
     * @returns {Promise<any>}
     */
    fetchProvidersForEmail(email: string) {
        return this.afAuth.auth.fetchProvidersForEmail(email);
    }

    /**
     * Send Password reset mail to the user,
     * Promise success = mail send
     * Promise fail = error
     *
     * @param email
     * @returns {Promise<any>}
     */
    sendPasswordResetEmail(mail) {
        this.analyticsProvider.logEvent('authentication', 'sendPasswordReset', 'mail', mail);
        return this.afAuth.auth.sendPasswordResetEmail(mail);
    }


    /**
     * Set Persistance of
     * LOCAL:   Indicates that the state will be persisted even when the browser window is closed or the activity is destroyed in react-native.
     * NONE:    Indicates that the state will only be stored in memory and will be cleared when the window or activity is refreshed.
     * SESSION: Indicates that the state will only persist in current session/tab, relevant to web only, and will be cleared when the tab is closed.
     * Documentation: https://firebase.google.com/docs/reference/js/firebase.auth.Auth#.Persistence
     *
     * @returns {Promise<any>}
     */
    setPersistance(state: string = this.config.config.authenticationPersistenceState) {
        //return this.afAuth.auth.setPersistence(state); //Commented to see if the persisted state is automatically correctly selected
    }

}
