/**
 * This barrel lists all references in the app.
 * Why: Because in this way we only need to change the reference in this file to point to the custom replacement file and we are finished.
 *  Keeping the entire core & shared package equal.
 *
 * Tip: With new custom functionality try to embed it in the shared submodule as a config variable instead of making a custom file.
 */

//*** Custom
export { CONF } from '../custom/conf';


//*** Shared
export { Config } from './models/config';

//export { AgendaProvider } from '../shared/providers/agendaItems'; //Gives strange DatabaseProvider not defined error
export { ConfigProvider } from './services/config';
export { DatabaseProvider } from './services/database';

//*** Core
export { CoreConfig } from '../core/models/coreConfig';
export { CoreConfigProvider } from '../core/services/coreConfig';
export { CoreDatabaseProvider } from '../core/services/coreDatabase';
export { RemoteConfigProvider } from '../core/services/remoteConfig';
