import {Injectable} from '@angular/core';
import {LoadingController, NavController, AlertController, ToastController} from '@ionic/angular';
import {TranslateService} from "@ngx-translate/core";

import {CoreConfig, CONF} from "../../custom";
import {CoreUser} from "../models/coreUser";

/*
 Generated class for the ConfigProvider provider.

 See https://angular.io/docs/ts/latest/guide/dependency-injection.html
 for more info on providers and Angular DI.
 */
@Injectable({providedIn: 'root'})
export class CoreConfigProvider {
    public loading: any;

    public _ready_promise: Promise<CoreConfig>;

    // This should normally be in the coreUserProvider.
    // But this is more convenient in config, as all classes have access to the config and thus the current user
    // So we don't need to refactor to have coreUser in all classes that needs auth.
    public currentAuthenticatedUser: CoreUser;


    public rightsAdmin: number = 75;
    public authUserRights: number = null;

    //Default Config
    public defaultConfig = {
        askRevenue: true,
        analyticTrackerId: 'UA-112049420-1',
        authenticationPersistenceState: 'local', //see https://firebase.google.com/docs/reference/js/firebase.auth.Auth#.Persistence
        authenticationMethods: ['mail'], //,'phone','facebook','google','twitter','github'
        auditLog: true,
        changeBusinessClubImage: true, //change business Club image
        changeOwnProfile: false,      //User can change its own profile or only mngmt?
        debug: 1,
        defaultLanguage: 'nl',
        enableSignup: false,
        googleStaticMapApi: 'AIzaSyCOB3EQ5Q6_1PQdbJt23kzotaFoHou5hPQ',
        isGenericVersion: false,
        isOnlineVersion: false,
        toastDuration: 3,
    } as CoreConfig;

    public config: CoreConfig = {} as CoreConfig;

    public navParams: any = {}; // Used when transitioning between pages.

    constructor(public alertCtrl: AlertController,
                public translate: TranslateService,
                public loadingCtrl: LoadingController,
                public toastCtrl: ToastController,
                public nav: NavController // Used by pages that use configprovider
    ) {

        this.init();
    }

    init() {
        this.loadConfig();
    }

    /**
     * Load the current configuration
     */
    loadConfig(remoteConfig = null) {
        return new Promise((resolve, reject) => {
            //Properties in the target object will be overwritten by properties in the sources if they have the same key.
            // Later sources' properties will similarly overwrite earlier ones.

            if (!remoteConfig) { //If no remoteConfig was given, then load the default & custom conf
                this.config = Object.assign(this.defaultConfig, CONF.config);
            } else {
                this.config = Object.assign(this.defaultConfig, CONF.config, remoteConfig);
            }

            this.debugLog("Loading Config", this.config);
            this._ready_promise = Promise.resolve(this.config);
            resolve(this.config);
        });
    }

    /**
     * ready helper when Analytics isn't ready yet.
     *
     * @returns {Promise<boolean>}
     */
    ready() {
        return this._ready_promise;
    }

    /**
     * General Navigation helper for all page transitions.
     * NavController in ionic4 uses Angular's router under the hood.
     * it's equivalent to call `this.router.navigateByUrl()`,
     * but it's explicit about the **direction** of the transition.
     *
     * @param to
     * @param params
     * @param directionForward
     * @param setAsRoot
     */

    /*navigate({page, params = null, directionForward = true, setAsRoot = false}:
                 { page: string, params?: any, directionForward?: boolean, setAsRoot?: boolean }) {

        // Set navParams if applicable
        this.setNavParams(params);

        if (setAsRoot) {
            return this.nav.navigateRoot(page);
        } else if (!directionForward) {
            return this.nav.navigateForward(page);
        } else if (directionForward) {
            return this.nav.navigateBack(page);
        }
    }*/

    /**
     * Getter for navParams
     */
    getNavParams() {
        return this.navParams;
    }

    /**
     * Setter for navParams
     */
    setNavParams(navParams) {
        this.navParams = navParams;
    }

    /**
     * Check rights
     * TODO move to own provider?
     *
     * @param {number} rightsNeeded
     * @param showToUser
     * @param showText
     * @returns {boolean}
     */
    hasRights(rightsNeeded: number = this.rightsAdmin, showToUser: boolean = false, showText: string = 'GENERAL.NO_RIGHTS') {
        this.debugLog("Checking rights, user has " + this.authUserRights + " and needs " + rightsNeeded);
        if (this.authUserRights >= rightsNeeded) {
            return true;
        } else {
            if (showToUser) {
                this.showToast({text: showText});
            }
            return false;
        }
    }

    /**
     * DebugLog function for custom logging
     *
     * @param logEntries
     */
    debugLog(...logEntries) {
        //console.log(logEntries);
        if (this.config.debug === 1) {
            console.log(logEntries);
        }
    }

    /**
     * ErrorLog function for custom logging
     *
     * @param errorEntry
     * @param errorTranslate
     * @param displayToUser
     */
    processError(errorEntry: any, errorTranslate?: string, displayToUser: boolean = false) {
        if ((errorTranslate) && (displayToUser)) {
            this.showToast({text: errorTranslate, duration: this.config.toastDuration * 1000 * 2});
        }

        //Log error in database (care that it isn't a loop? )
        //Carefull as this will break tests (unable to load provider firebase)
        //this.firebase.logError(errorEntry);

        if (this.config.debug === 1) {
            console.error(errorEntry);
        }
    }

    /**
     * Show loading dialog
     */
    showLoading(loadingTranslateText: string = 'GENERAL.LOADING') {
        this.hideLoading();
        this.translate.get(loadingTranslateText).subscribe(async (value) => {
            this.loading = await this.loadingCtrl.create({
                message: value,
            });
            await this.loading.present();
        });
    }

    /**
     * Hide the loader
     */
    hideLoading() {
        if (this.loading) {
            this.loading.dismiss();
            this.loading = null;
        }
    }

    /**
     * Helper function to show an alertBox, resolve true after click
     *
     * @param title
     * @param text
     * @param buttonText
     */
    showAlert(title: string, text: string, buttonText: string = 'Ok') {
        return new Promise<boolean>((resolve, reject) => {
            this.alertCtrl.create({
                header: title,
                subHeader: text,
                buttons: [
                    {
                        text: buttonText,
                        cssClass: 'testOk',
                        handler: () => {
                            resolve(true);
                        }
                    }
                ]
            }).then((alert) => alert.present());
        });
    }

    /**
     * Helper function to show a prompt, resolve input after click
     *
     * @param title
     * @param message
     * @param buttonText
     * @param buttonText2
     * @param promptName
     * @param promptPlaceholder
     */
    showPrompt({title, message, buttonText = 'GENERAL.CONFIRMATION_CANCEL', buttonText2 = 'GENERAL.CONFIRMATION_OK', promptName = 'promptEntry', promptPlaceholder = ''}: {
        title: string, message: string, buttonText?: string, buttonText2?: string, promptName?: string, promptPlaceholder?: string
    }) {
        return new Promise<string>((resolve, reject) => {
            this.translate.get([title, message, buttonText, buttonText2]).subscribe((promptText) => {
                let prompt = this.alertCtrl.create({
                    header: promptText[title],
                    message: promptText[message],
                    inputs: [
                        {
                            name: promptName,
                            /*placeholder: promptText[promptPlaceholder],*/
                        },
                    ],
                    buttons: [
                        {
                            text: promptText[buttonText],
                            cssClass: 'testCancel',
                            handler: () => {
                                resolve();
                            }
                        },
                        {
                            text: promptText[buttonText2],
                            cssClass: 'testOk',
                            handler: (data) => {
                                resolve(data);
                            }
                        }
                    ]
                }).then((prompt) => prompt.present());
            });
        });
    }

    /**
     * Show error on toast
     *
     * @param error
     */
    showToast({
                  text,
                  type = 'error',
                  noHide = false,
                  duration = this.config.toastDuration * 1000,
                  translationParams = {},
              }: {
        text: string,
        translationParams?: object,
        type?: string,
        noHide?: boolean,
        duration?: number,
    }) {
        if (text) { //Todo standard text if no text is given?
            let toastObject = {
                showCloseButton: true,
            };
            if (!noHide) toastObject['duration'] = duration;
            toastObject['cssClass'] = type.toLowerCase();
            this.translate.get('GENERAL.CLOSE').subscribe((closeButtonText) => {
                toastObject['closeButtonText'] = closeButtonText;
                this.translate.get(text, translationParams).subscribe((value) => {
                    toastObject['message'] = value;
                    this.toastCtrl.create(toastObject).then((toast) => toast.present());
                });
            });

        }

    }

    /**
     * Show Confirmation Dialog, resolve true if continue, false for stop.
     *
     * @param {string} title
     * @param {string} subTitle
     * @param {string} cancelButtonText
     * @param {string} continueButtonText
     * @param translationParams
     * @returns {Promise<boolean>}
     */
    showConfirmationDialog({
                               title,
                               subTitle,
                               continueButtonText = 'GENERAL.CONFIRMATION_OK',
                               cancelButtonText = 'GENERAL.CONFIRMATION_CANCEL',
                               translationParams = {},
                           }: {
        title: string,
        subTitle: string,
        translationParams?: object,
        cancelButtonText?: string,
        continueButtonText?: string
    }): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.translate.get([title, subTitle, continueButtonText, cancelButtonText], translationParams).subscribe((value) => {
                let alert = this.alertCtrl.create({
                    header: value[title],
                    subHeader: value[subTitle],
                    buttons: [
                        {
                            text: value[cancelButtonText],
                            cssClass: 'testCancel',
                            handler: () => {
                                resolve(false);
                            }
                        },
                        {
                            text: value[continueButtonText],
                            cssClass: 'testContinue',
                            handler: () => {
                                resolve(true);
                            }
                        }
                    ]
                }).then((alert) => alert.present());
            });
        });
    }

    /**
     * Get all filters, to be set by child configProvider
     */
    getFilters() {
        return [];
    }

    //*** HELPER FUNCTIONS

    /**
     * Helperfunction to convert strings to numbers
     * @param {string} valueString
     * @param roundNumber
     */
    static convertStringToNumber(valueString: string, roundNumber: number = 0): number {
        //Replace all comma's to a point which can be used in the dashboard
        valueString = valueString.replace(/,/g, ".");
        let valueNumber: number = parseFloat(valueString);
        valueNumber = this.round(valueNumber, roundNumber);
        return valueNumber;
    }

    /**
     * Helper function for rounding numbers
     *
     * @param value
     * @param decimals
     * @returns {number}
     */
    static round(value, decimals = 0) {
        let base10 = Math.pow(10, decimals);
        return (Math.round(value * base10) / base10);
    }
}
