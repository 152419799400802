import {Injectable} from '@angular/core';
import { Platform} from '@ionic/angular';
import {Firebase} from '@ionic-native/firebase/ngx';
import {GoogleAnalytics} from '@ionic-native/google-analytics/ngx';

import {ConfigProvider} from "../../custom";
import {CoreAnalyticsProvider} from "../../core/services/coreAnalytics";

@Injectable()
export class AnalyticsProvider extends CoreAnalyticsProvider {

    constructor(public googleAnalytics: GoogleAnalytics,
                public firebase: Firebase,
                public config: ConfigProvider,
                public platform: Platform) {

        super(googleAnalytics, firebase, config, platform);

    }
}