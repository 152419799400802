import {Injectable} from '@angular/core';
import {Config, Events, Platform} from '@ionic/angular';
import {AngularFireAuth} from '@angular/fire/auth';
import * as firebase from 'firebase/app';

import {ConfigProvider} from "../";
import {DatabaseProvider} from "./database";
import {AnalyticsProvider} from "./analytics";
import {TranslateService} from "@ngx-translate/core";
import {ReplaySubject} from "rxjs";
import {CoreUser} from "../../core/models/coreUser";
import {CoreAuthenticationProvider} from "../../core/services/coreAuthentication";
import {UserProvider} from './user.service';

/**
 * Authentication Provider for Firebase login
 * Documentation: https://firebase.google.com/docs/reference/js/firebase.auth.Auth
 *
 * Documentation on how to implement @angular/fire in ionic3:
 * https://github.com/angular/@angular/fire/blob/master/docs/ionic/v3.md
 *
 */
@Injectable()
export class AuthenticationProvider extends CoreAuthenticationProvider {

    public userSessionVariables: ReplaySubject<any> = new ReplaySubject(1);

    constructor(public config: ConfigProvider,
                public afAuth: AngularFireAuth,
                public translate: TranslateService,
                public platform: Platform,
                public analytics: AnalyticsProvider,
                public database: DatabaseProvider,
                public user: UserProvider,
                public events: Events,
                public ionicConfig: Config,
                //public app: App,
    ) {

        super(config, afAuth, translate, platform, ionicConfig, analytics, database, user, events);


    }

    /**
     * User is log'd with coreAuthentication, we can do some processing now.
     * Must return a promise.
     *
    extendLogin(instance: string = null, authenticatedUser: CoreUser): Promise<CoreUser> {
        return new Promise((resolve, reject) => {

            this.config.authUserRights = authenticatedUser['role'];

            this.loadUserSessionVariables(this.uid).then(() => {
                resolve(authenticatedUser);
            });
        });
    }*/

    /**
     * Load the user session variables, like calculated recommendations, logbook lastread entries, ...
     *
     */
    loadUserSessionVariables(userId: string = this.uid) {
        return new Promise((resolve, reject) => {
            //Get demands for profile page
            this.database.getSessionUserVariables(userId).subscribe((userSessionVariables) => {

                this.userSessionVariables.next(userSessionVariables);

                //Todo breakdown subscription

                /* if (!lastDemandReadTimeStamp) {
                     lastDemandReadTimeStamp = null;
                 }
                 else {
                     lastDemandReadTimeStamp = Number(lastDemandReadTimeStamp);
                 }
                 this.lastDemandReadTimeStamp = lastDemandReadTimeStamp;

                 this.database.getMissedDemands(this.business.id, lastDemandReadTimeStamp).subscribe((unreadDemandsRaw) => {
                     let unreadDemands = 0;
                     if ((unreadDemandsRaw.length) && (unreadDemandsRaw.length > 0)) {
                         unreadDemands = unreadDemandsRaw.length;
                     }
                     //previousRecommendationsUnread as we only want to update recommendationList on increments
                     this.events.publish('demands:changes', unreadDemands, this.previousRecommendationsUnread);
                     this.config.debugLog("User has " + unreadDemands + " unread demands");
                     this.previousDemandsUnread = unreadDemands;
                 });
             });*/
            });

            resolve();
        });

    }

}
