import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import {DataTablesModule} from 'angular-datatables';
import { AngularFireModule } from '@angular/fire';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireDatabaseModule, AngularFireDatabase } from '@angular/fire/database';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {CONF, ConfigProvider, CoreConfigProvider, DatabaseProvider} from '..';
import {UserProvider} from '../services/user.service';
import {AuthenticationProvider} from '../services/authentication';
import {StorageProvider} from '../services/storage';
import {AnalyticsProvider} from '../services/analytics';
import {GoogleAnalytics} from '@ionic-native/google-analytics/ngx';
import {Firebase} from '@ionic-native/firebase/ngx';
import {NotificationProvider} from '../services/notification';
import {ShareProvider} from '../services/share';

// The translate loader needs to know where to load i18n files
export function staticTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (staticTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp(CONF.firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    DataTablesModule.forRoot(),
    AppRoutingModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AngularFireDatabase,
    CoreConfigProvider,
    ConfigProvider,
    DatabaseProvider,
    AuthenticationProvider,
    UserProvider,
    GoogleAnalytics,
    AnalyticsProvider,
    Firebase,
    StorageProvider,
    NotificationProvider,
    ShareProvider,

  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
