import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/take';

import * as moment from 'moment';

import {ConfigProvider} from '../';
import {CoreDatabaseProvider} from '../../core/services/coreDatabase';
import {of} from 'rxjs';

@Injectable()
export class DatabaseProvider extends CoreDatabaseProvider {

    private branchMain: string = 'v0'; //v0
    private branchUser: string = this.branchMain + '/user';
    private branchUserDeviceTokens: string = this.branchMain + '/userTokens/';
    private branchUserList: string = this.branchMain + '/userList';
    private branchUserSessionVars: string = this.branchMain + '/userSessionVars';


    constructor(public afDB: AngularFireDatabase,
                public config: ConfigProvider) {
        super(afDB, config);
    }


    /**
     * Adds a device token for the user to receive push notifications
     *
     * @param {string} userId
     * @param {string} deviceToken
     * @returns {Promise<any>}
     */
    addUserDeviceToken(userId: string, deviceToken: string) {
        return this.updateObject(`/${this.branchUserDeviceTokens}/${userId}/${deviceToken}`, true);
    }

    /**
     * Get the user Session Variables
     *
     * @param {string} userId
     */
    getSessionUserVariables(userId: string) {
        return this.getObject(`/${this.branchUserSessionVars}/${userId}`, false, true);
    }

    /**
     * Set User Session Variable
     *
     * @param userId
     * @param variableName
     * @param value
     * @returns {any}
     */
    setUserSessionVariable(userId, variableName, value) {
        if ((!userId) || (!variableName) || (!value)) {
            this.processError(new Error('userId, variableName or value missing'), 'DATABASE.ERROR_ADD');
            return Promise.resolve(false);
        }
        return this.updateObject(`/${this.branchUserSessionVars}/${userId}/${variableName}`, value);
    }

    /**
     *
     * @param {string} path
     * @param {any} startAt
     * @returns {any}
     */
    getUnreadItems(path: string, startAt = null) {
        if (!path) {
            this.processError('path missing', 'DATABASE.ERROR_UPDATE');
            return of(false);
        }
        return this.getReferencesOfIds(`/${this.branchMain}/${path}`, false, 'desc', startAt);
    }
}
