import {Injectable} from '@angular/core';
import {AlertController, LoadingController, Platform, ToastController, NavController} from '@ionic/angular';
import {TranslateService} from "@ngx-translate/core";

import {CoreConfigProvider} from "./coreConfig"; //Todo why doesn't this work in custom barrel?
import {CoreConfig} from "../models/coreConfig";

@Injectable()
export class RemoteConfigProvider extends CoreConfigProvider {

    private loadRemoteConfig: boolean = true; //Do we want to use remote config or not?
    private isRemoteConfigLoaded: boolean = false;

    //Remote Config with default values
    public config: CoreConfig = this.defaultConfig;

    /*public config = {
        analyticTrackerId: 'UA-112049420-1',
        authenticationPersistenceState: 'local', //see https://firebase.google.com/docs/reference/js/firebase.auth.Auth#.Persistence
        debug: 1,
        toastDuration: 3,
        defaultLanguage: 'nl',
    } as Config;*/

    constructor(public platform: Platform,
                //private firebase: Firebase,
                public alertCtrl: AlertController,
                public translate: TranslateService,
                public loadingCtrl: LoadingController,
                public toastCtrl: ToastController,
                public nav: NavController) {

        super(alertCtrl, translate, loadingCtrl, toastCtrl, nav);


        if (this.loadRemoteConfig) {
            this._ready_promise = this.loadAndActivateRemoteConfig();
        }
        else {
            this._ready_promise = Promise.resolve(this.config);
        }
    }

    /**
     * Fetch the external config and activate it (= copy fetched config to the active)
     * Todo change resolve empty to reject?
     */
    loadAndActivateRemoteConfig() {
        //return Promise.resolve(true);
        return new Promise<CoreConfig>((resolve, reject) => {
            this.platform.ready().then(() => {
                if (this.platform.is("cordova")) {
                    (<any>window).FirebasePlugin.fetch(600, result => {
                        // activate the fetched remote config
                        (<any>window).FirebasePlugin.activateFetched(
                            isActivated => {
                                if (isActivated) {
                                    this.getRemoteConfigVariables().then(() => {
                                        this.debugLog("Remote Config activated Succesfully");
                                        this.isRemoteConfigLoaded = true;
                                        return resolve(this.config);
                                    }).catch((error) => {
                                        return resolve({} as CoreConfig);
                                    });
                                }
                                else {
                                    this.debugLog("Error: Remote Config Not Activated!");
                                    return resolve({} as CoreConfig);
                                }
                            }, reason => {
                                this.debugLog("Error: Remote Config Not loaded!");
                                return resolve({} as CoreConfig);
                            }
                        )
                    });

                    //Ionic Native wrapper doesn't work yet. Work around above
                    /*   this.globalEnv.debugLog("fetching Remote config");
                       this.firebase.fetch()
                           .then((result) => {
                               this.globalEnv.debugLog(result);
                               return this.firebase.activateFetched();
                           })
                           .then((isActivated) => {
                               if (isActivated) {
                                   this.globalEnv.debugLog("Remote Config loaded succesfully");
                                   this.isRemoteConfigLoaded = true;
                               }
                               else {
                                   this.globalEnv.debugLog("Error: Remote Config Not loaded!!");
                               }
                           })
                           .catch((error) => {
                               this.globalEnv.processError(error, "Error: Remote Config Not loaded!");
                           })*/
                }
                else {
                    //Todo Reject or custom code for webbrowsers?
                    return resolve({} as CoreConfig);
                }
            });
        });
    }

    /**
     * Get Config Value of a given field
     *
     * @param varName
     * @returns {any}
     */
    getValue(varName: string): Promise<any> {
        if (this.isRemoteConfigLoaded) {
            // return this.firebase.getValue(varName); // Not working yet, work around:
            return new Promise((resolve, reject) => {
                (<any>window).FirebasePlugin.getValue(varName, result => {
                    //As we receive strings, we must manualy check if it is a number and convert it.
                    if (!isNaN(parseInt(result))) {
                        resolve(parseInt(result));
                    }
                    else {
                        resolve(result);
                    }
                }, reason => {
                    this.processError(reason, "Error getting " + varName + " from remote config");
                    reject(reason);
                });
            });
        }
        else {
            if (varName in this.config) {
                return Promise.resolve(this.config[varName]);
            }
            else return Promise.reject('Config Value not found');
        }
    }

    /**
     * Get all keys from variable config & get the remote variable of it.
     */
    getRemoteConfigVariables() {
        return new Promise<boolean>((resolve, reject) => {
            let promiseMap = [];
            const configVariables = Object.keys(this.config);
            configVariables.forEach((key) => {
                promiseMap[key] = this.getValue(key);
            });
            this.promiseMapAll(promiseMap).then((config: CoreConfig) => {
                //Todo is overwrite needed or do we need to merge?
                this.config = config;
                this.debugLog(config, "Received remote config.");
                return resolve(true);
            }).catch(error => {
                reject(error);
            });
        });
    }

    /**
     * Helper function to resolve all key: value (promises)
     * Inspiration https://stackoverflow.com/questions/29292921/how-to-use-promise-all-with-an-object-as-input
     *
     * @param promiseMap
     * @returns {Promise<any>}
     */
    async promiseMapAll(promiseMap) {
        try {
            const promiseMapPromises = Object.keys(promiseMap).map(key => promiseMap[key]);
            const promises = await Promise.all(promiseMapPromises);
            // Promise.all(Object.values(promiseMap)); //Ecma 2017
            let objMapped = {};
            Object.keys(promiseMap).forEach((key, i) => {
                objMapped[key] = promises[i];
            });
            return objMapped;
        } catch (err) {
            return {err};
        }
    }
}
