import {Injectable } from '@angular/core';
import {CoreConfigProvider} from "./coreConfig";
import {CoreItemProvider} from "./coreItemProvider";
import {AbstractItem, ItemClass} from "../models/abstractItem";
import {CoreDatabaseProvider} from "./coreDatabase";

/***
 * As a core User can be different from a user, this is the case in multi instance applications,
 * where one coreuser can have access to multiple instances.
 */

@Injectable()
export class CoreUserProvider extends CoreItemProvider implements ItemClass {

    branchItem = 'user';

    constructor(public config: CoreConfigProvider,
                public database: CoreDatabaseProvider,) {

        super(config, database);

    }

    /**
     * get the coreUser
     * as an app can have multiple instances and every user can thus be in multiple instances
     * we have a coreUser object to store the global settings for a user, like language, ...
     *
     *
     * @param {string} id
     */
    getCoreUser(id:string) {
        if (true == this.branchIsMainBranch) {
            // If the branch is already main,
            // That means we don't use instances for this app
            // so we can grab the item directly
            return this.getItem(id);
        }
        else {
            // Set temporary the branch as main branch
            this.branchIsMainBranch = true;

            // Build the branch without suffix
            let branch = this.assembleBranch({id: id, addItemSuffix: false});

            // Reset to the old value
            this.branchIsMainBranch = false;
            return this.database.getItem(branch, id);
        }
    }

    /**
     * set the core user
     *
     * @param {AbstractItem} item
     * @returns {Promise<boolean> | Promise<any>}
     */
    updateCoreUser(item: AbstractItem) {
        if (true == this.branchIsMainBranch) {
            // If the branch is already main,
            // That means we don't use instances for this app
            // so we can grab the item directly
            return this.updateItem(item);
        }
        else {
            // Set temporary the branch as main branch
            this.branchIsMainBranch = true;

            // Build the branch without suffix
            let branch = this.assembleBranch({id: item.id, addItemSuffix: false});

            // We don't use extendUpdateItem here.

            // Reset to the old value
            this.branchIsMainBranch = false;

            return this.database.updateItem(branch, item);
        }
    }


}
