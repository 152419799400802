import {Injectable} from '@angular/core';
import {AbstractItem} from "../models/abstractItem";
import {Observable} from 'rxjs';

// import * as moment from 'moment';
import {CoreConfigProvider} from "./coreConfig";
import {CoreDatabaseProvider} from "./coreDatabase";
import {AuditEntry} from "../models/auditEntry";

/**
 * Generic ItemProvider
 * How to add a new provider:
 * 1. Genterate new provider class, use extends ItemProvider implements ItemClass
 * 2. super(config,database,user)
 * 3. set variable branchItem
 */
@Injectable()
export abstract class CoreItemProvider {

    public item: AbstractItem;
    public _ready_promise: Promise<any>;

    //To be filled in by child
    public neededRights: number = 0;
    public abstract branchItem: string;
    public branchMain: string;

    public branchPrefix: string;
    public branchIsMainBranch: boolean = false;

    public itemSuffix: string; //Suffix for the add operation e.g. /profile on business

    public branchList: string; //What is the list branch of the items (default: branchItem + listExtension)
    public listExtension: string = 'List'; //Extension between item and


    public filterStartAt: string; //Start at which item in getItems
    public filterEndAt: string; //End at which item in getItems
    public filterSort: string = 'asc'; //Default ascending order (or change to 'desc')

    //Variables to craete dividers per itemReferenceList
    public dividersPerItem = []; //e.g. Dividers per date
    public dividersPerId = [];
    public dividerDateFormat: string = "DD";
    public dividerIsDate: boolean = false;

    public auditBranchName: string = "audit";

    public addConfirmation: boolean = false;
    public addConfirmationText: string = 'GENERAL.ADD_CONFIRMATION';

    public deleteConfirmation: boolean = true;
    public deleteConfirmationText: string = 'GENERAL.DELETE_CONFIRMATION';

    constructor(public config: CoreConfigProvider,
                public database: CoreDatabaseProvider) {

        this.initCoreItemProvider();

    }

    initCoreItemProvider() {

        if ((this.config.config.dbBranchMain) && (!this.branchMain)) {
            this.branchMain = this.config.config.dbBranchMain;
        }


        if ((this.config.config.dbBranchPrefix) && (!this.branchPrefix)) {
            this.branchPrefix = this.config.config.dbBranchPrefix;
        }
    }

    /**
     * ready helper when Provider hasn't finished loading.
     *
     * @returns {Promise<boolean>}
     */
    ready() {
        return this._ready_promise;
    }

    /**
     * Helper function to see if it has rights to process item.
     *
     * @param {number} rights
     * @param {boolean} show
     * @returns {boolean}
     */
    hasRights(rights: number, show: boolean = true) {
        if (rights >= this.neededRights) {
            return true;
        }
        else return false;
    }

    /**
     * Add (push) an item to the database
     *
     * @param {abstractItem} item
     * @param addConfirmation
     * @returns {Promise<boolean> | Promise<any>}
     */
    addItem(item: AbstractItem, addConfirmation: boolean = this.addConfirmation) {
        return new Promise<any>((resolve, reject) => {
            const branch = this.assembleBranch({addItemSuffix: false});

            //Check if we need to add a prefix. e.g. /profile with business.
            if (this.itemSuffix) {
                let itemWithAddPrefix = {};
                itemWithAddPrefix[this.itemSuffix] = item;
                item = itemWithAddPrefix;
            }

            if (addConfirmation) {
                this.config.showConfirmationDialog({
                    title: 'GENERAL.WARNING',
                    subTitle: this.addConfirmationText
                }).then((confirmation) => {
                    if (confirmation) {
                        resolve(this.database.addItem(branch, item));
                    }
                    else {
                        resolve(false);
                    }
                });
            }
            else {
                resolve(this.database.addItem(branch, item));
            }
        });
    }

    /**
     * Get a list of reference id's
     *
     * @returns {Observable<any>}
     */
    //(userId: string=null, recommendationType: string=null, onlyReferences:boolean = true) {
    getItems({branch = null, filterSort = this.filterSort, filterStartAt = this.filterStartAt, filterEndAt = this.filterEndAt, raw = false}:
                 { branch?: string, filterSort?: string, filterStartAt?: string, filterEndAt?:string, raw?: boolean }) {

        if (!branch) {
            if (this.branchList) {
                branch = this.branchList;
            }
            else {
                branch = this.branchItem + this.listExtension;
            }
        }

        let getItemsArguments = {
            branch: this.assembleBranch({branch: branch, addItemSuffix: false}),
            sort: filterSort,
        };

        if (filterStartAt) {
            getItemsArguments['startAt'] = filterStartAt;
        }

        if (filterEndAt) {
            getItemsArguments['endAt'] = filterEndAt;
        }

        if (raw) {
            getItemsArguments['raw'] = raw;
        }

        return this.database.getItems(getItemsArguments);


        /*
        Divider functionality, not finished and to test
        *return new Observable<any>((refsObserver) => {

            /* let sortAsc: boolean = true;
             let sortBy: string = 'number'; //unused //Sort by number (default), time (for divider formatting), string.
             if (this.segment !== 'planning') {
                 sortAsc = false;
             }*


            const refSubscription = this.database.getItems(getItemsArguments).subscribe(
                (allRefs) => {

                    let idsArray = [];
                    this.dividersPerItem = [];
                    this.dividersPerId = [];

                    let itemsRefArray = []; //key = id, value = value (e.g. time, string, ...) on which we can sort on.

                    //Populate itemsArray
                    let canSortOnNumber = true;
                    allRefs.forEach((arrayEntry) => {
                        let value = arrayEntry.payload.val();

                        if (isNaN(value)) canSortOnNumber = false;
                        else value = Number(value);

                        itemsRefArray.push({key: arrayEntry['key'], value: value});

                    });

                    //Sort based on number or string
                    if (canSortOnNumber) { //Number
                        if (filterSort === 'asc') {
                            itemsRefArray.sort((a, b) => {
                                return a.value - b.value
                            });
                        }
                        else {
                            itemsRefArray.sort((a, b) => {
                                return b.value - a.value
                            });
                        }
                    }
                    else { //String
                        if (filterSort === 'asc') {
                            itemsRefArray.sort((a, b) => {
                                let x = a.type.toLowerCase();
                                let y = b.type.toLowerCase();
                                if (x < y) {
                                    return -1;
                                }
                                if (x > y) {
                                    return 1;
                                }
                                return 0;
                            });
                        }
                        else {
                            itemsRefArray.sort((a, b) => {
                                let x = a.type.toLowerCase();
                                let y = b.type.toLowerCase();
                                if (x > y) {
                                    return -1;
                                }
                                if (x < y) {
                                    return 1;
                                }
                                return 0;
                            });
                        }
                    }

                    //
                    itemsRefArray.forEach((itemRefObject) => {
                        let key = itemRefObject.key;
                        let time = itemRefObject.value;

                        if (this.dividerIsDate) {
                            let currentDateFormat = moment(Number(time)).format(this.dividerDateFormat);
                            //Check if a divider is already set for this group, if not add it.
                            //If a divider is already set, check this one is earlier. (shouldn't be with this sort, but you never know).

                            if (filterSort === 'asc') {
                                if (!this.dividersPerItem[currentDateFormat] || ((this.dividersPerItem[currentDateFormat]) && (this.dividersPerId[this.dividersPerItem[currentDateFormat]] > time))) {
                                    this.dividersPerItem[currentDateFormat] = key;
                                    this.dividersPerId[key] = time;
                                }
                            }
                            else {
                                if (!this.dividersPerItem[currentDateFormat] || ((this.dividersPerItem[currentDateFormat]) && (this.dividersPerId[this.dividersPerItem[currentDateFormat]] < time))) {
                                    this.dividersPerItem[currentDateFormat] = key;
                                    this.dividersPerId[key] = time;
                                }
                            }
                        }
                        else {

                        }


                        idsArray.push(key);
                    });

                    //Pass only the Ids to the abstractPage
                    refsObserver.next(idsArray);
                },
                (error) => {
                    this.config.debugLog(error);
                }
            );

            //Teardown logic
            return () => {
                refSubscription.unsubscribe();
            };
        });*/
    }

    /**
     * Retrieve all items, bypassing the reference list.
     */
    getAllItems({}) {
        let branch = this.assembleBranch({addItemSuffix: false});

        return this.database.getAllItems({branch: branch});
    }

    /**
     * Check if the Item is the first in his group and should have a divider.
     *
     * @param item
     * @returns {boolean}
     */
    checkNewDivider(item) {
        if (this.dividersPerId[item.id]) {
            return true;
        }
        else return false;
    }

    /**
     * Get an item by id
     *
     * @param {string} id
     * @returns {Observable<boolean> | Observable<any>}
     */
    getItem(id: string = null) {
        //Todo trow error when id is not set?
        const branch = this.assembleBranch({id: id});

        return this.database.getItem(branch, id);
    }

    /**
     * Update an item
     *
     * @param {abstractItem} item
     * @returns {Promise<boolean> | Promise<any>}
     */
    updateItem(item: AbstractItem) {
        const branch = this.assembleBranch({id: item.id});

        if (typeof this.extendUpdateItem === "function") {
            item = this.extendUpdateItem(item);
        }

        return this.database.updateItem(branch, item);
    }

    /**
     * Function to extend the updateItem function in the child (for extra processing if needed).
     *
     * @param item
     */
    extendUpdateItem(item) {
        return item;
    };

    /**
     * Delete an item
     * Todo: if this.softDelete = true ...
     *
     * @param {string} id
     * @param askConfirmation
     * @returns {Promise<boolean> | Promise<any>}
     */
    //Is used by FormPage & Abstract
    deleteItem(id: string, askConfirmation: boolean = this.deleteConfirmation) {
        return new Promise<any>((resolve, reject) => {
            const branch = this.assembleBranch({id: id});
            if (askConfirmation) {
                this.config.showConfirmationDialog({
                    title: 'GENERAL.WARNING',
                    subTitle: this.deleteConfirmationText
                }).then((confirmation) => {
                    if (confirmation) {
                        resolve(this.database.deleteItem(branch));
                    }
                    else {
                        resolve(false);
                    }
                });
            }
            else {
                resolve(this.database.deleteItem(branch));
            }
        });
    }

    /**
     * Search for the meaning of life
     *
     * @param {string} searchString
     */
    searchItem(searchString: string) {
        //Todo
    }

    // Report functions


    /*
    1. Get items (not references) with startAt & endAt (set via variable)
        foreach item
            reportFilter //if value of a field meets a preset condition set in filter: [{field: fieldName, operater: equal, value: 'name'},...]
            foreach reportFields //reportFields = ['fieldName1','FieldName2',...] //Of fields we need to capture.
                switch(reportCalculate['fieldName']) {
                    case 'max':
                       if (this.reportCalculations['fieldName'] < value) this.reportCalculations['fieldName'] = value;
                       break;
                     case 'min':
                       if (this.reportCalculations['fieldName'] > value) this.reportCalculations['fieldName'] = value;
                       break;
                     case 'sum':
                        this.reportCalculations['fieldName'] += value;
                        break;
                    case 'avg':
                        CONTINUE HEERE
                    default:
                        Do Nothing?
                }

                if Field is user in a dataTable Store it in an array.
                



        //Add the child data (of one Schedule)
                        let schedule: Schedule = rawSchedule.payload.val();

                        //Add the id/key with the data for future reference.
                        schedule.id = rawSchedule.payload.key;




     */


    /**
     * To be complient with "Wet Bescherming persoonsgegevens moet een logboek bijgehouden worden.
     * THet opstellen van een register van verwerkingsactiviteiten (verwerkingsregister) is onder de AVG vaak een verplichte maatregel.
     * Of u zo'n register moet opstellen, hangt af van de omvang van uw organisatie en het type gegevens dat u verwerkt.
     *
     * @param {string} logMessage
     * @returns {Promise<boolean> | Promise<any>}
     */
    addAuditLog(screenName: string, logMessage: string) {
        // Only process if we need to keep an auditLog as defined in the config.
        if (this.config.config.auditLog) {
            let auditEntry: AuditEntry = {
                timestamp: CoreDatabaseProvider.timestamp(),
                userId: this.config.currentAuthenticatedUser.id,
                userMail: this.config.currentAuthenticatedUser.mail,
                screenName: screenName,
                logMessage: logMessage
            };

            let auditBranch = this.assembleBranch({branch: this.auditBranchName, addItemSuffix: false});

            return this.database.addItem(auditBranch, auditEntry);
        }
    }


    // Helper functions

    /**
     * Add the instance branch and format it for processing.
     *
     * @param branch
     * @param id
     * @param {string} branchWithoutPrefix
     * @param addItemSuffix
     * @param {string} instance
     * @returns {string}
     */
    protected assembleBranch({
                                 branch = this.branchItem,
                                 id = null,
                                 branchWithoutPrefix = null,
                                 addItemSuffix = true,
                                 instance = this.config.config.instance
                             }: {
        branch?: string,
        id?: string,
        branchWithoutPrefix?: string,
        addItemSuffix?: boolean,
        instance?: string
    }) {
        let branchWithPrefix = '';

        if (this.branchPrefix) {
            branchWithPrefix += `/${this.branchPrefix}`;
        }

        if ((!this.branchIsMainBranch) && (this.branchMain)) {
            branchWithPrefix += `/${this.branchMain}`;
        }

        if ((instance) && (!this.branchIsMainBranch)) {
            branchWithPrefix += `/${instance}`;
        }

        branchWithPrefix += `/${branch}`;


        if (id) {
            branchWithPrefix = `${branchWithPrefix}/${id}`;
        }

        //let branchWithPrefix = `/${this.branchPrefix}/${this.branchInstance}/${instance}/${branchWithoutPrefix}`;

        if ((addItemSuffix) && (this.itemSuffix)) {
            branchWithPrefix = `${branchWithPrefix}/${this.itemSuffix}`
        }

        return branchWithPrefix;
    }
}
