import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {Observable, of} from 'rxjs';
import * as firebase from 'firebase'; //Needed for timestamp
import "rxjs/add/operator/map";
import 'rxjs/add/operator/take';

import {Subscription} from "rxjs";
import {AbstractItem} from "../models/abstractItem";
import {CoreConfigProvider} from "./coreConfig";

@Injectable({ providedIn: 'root' })
export class CoreDatabaseProvider {

    public isOnline: boolean = false; // Connection State

    private activeSubscriptions: Subscription[] = [];

    // constructor(public afDB: AngularFirestore) {


    // If the app can operate offline, set the next flag to false in the child
    // And only on the pages  that can't handle offline e.g. login
    // use a ngSwitch on this.databaseProvider.isOnline
    // to display a nice (fullpage/slide) offline message
    public showOfflineIndicator: boolean = true;
    private isOfflineTimer;
    public showOfflineAfterSeconds = 3;


    constructor(public afDB: AngularFireDatabase,
                public config: CoreConfigProvider) {
        this.stateListener();
    }

    /**
     * Set Listener for database state changes between online and offline accessible via isOnline boolean
     */
    stateListener() {
        this.afDB
            .object('.info/connected')
            .valueChanges()
            //.map(info => info.connected)
            .subscribe(
                (value: boolean) => {
                    if (value) this.config.debugLog("Connection State: Online");
                    else this.config.debugLog("Connection State: Offline");


                    // Show if app is Offline to the user (if needed)
                    if (this.showOfflineIndicator) {
                        if (false === value) {
                            // Offline, show loading indicater after X seconds,
                            // Otherwise it is always shown at the start of the app.
                            this.isOfflineTimer = setTimeout(() => {
                                this.config.showLoading('DATABASE.OFFLINE');
                                delete this.isOfflineTimer;
                            }, this.showOfflineAfterSeconds * 1000
                            );
                        }
                        else {
                            if (this.isOfflineTimer) {
                                clearTimeout(this.isOfflineTimer);
                                delete this.isOfflineTimer;
                            }
                            else this.config.hideLoading();
                        }
                    }


                    this.isOnline = value;
                }
            );
    }

    //*** GENERAL Item Provider Functions

    /**
     * Push an Item to the database
     *
     * @param {string} branch
     * @param {abstractItem} item
     * @returns {any}
     */
    addItem(branch: string, item: AbstractItem) {
        if ((!branch) || (!item)) {
            this.processError(new Error("Could not add Item, branch or item missing"), "DATABASE.ERROR_ADD");
            return Promise.resolve(false);
        }
        return this.pushObject(`${branch}`, item);
    }

    /**
     * Get list of Ids of items
     * @returns {Observable<any>}
     */
    getItems({branch, sort = 'asc', startAt = null, endAt = null, raw = false}: { branch: string, sort?: string, startAt?: string, endAt?: string, raw?: boolean }) {
        if (!branch) {
            this.processError("Could not get Items branch missing", "DATABASE.ERROR_GET");
            return of(false);
        }

        //Todo also change to object
        return this.getReferencesOfIds(`/${branch}`, false, sort, startAt, raw, endAt);
    }

    /**
     * Generic Helper function for data needed for reports/dashboards.
     *
     * @param {string} businessClubId
     */
    getAllItems({branch}: { branch: string}) {
        return this.getObject(`/${branch}`);
    }

    /**
     * Get Item with Id
     *
     * @param branch
     * @param id
     * @param once
     */
    getItem(branch: string, id: string = null) {
        if (!branch) {
            this.processError("Unable to get item, branch missing", "DATABASE.ERROR_GET");
            return of(false);
        }
        return this.getObject(`/${branch}`, false, true, id);
    }

    /**
     * Update Item
     *
     * @returns {any}
     * @param branch
     * @param item
     */
    updateItem(branch: string, item: AbstractItem) {
        if ((!branch) || (!item)) {
            this.processError("Unable to update Item, branch or item missing", "DATABASE.ERROR_UPDATE");
            return Promise.resolve(false);
        }
        return this.updateObject(`/${branch}`, item);
    }

    /**
     * Delete an item
     * references will should be" automatically deleted by the cloud functions
     *
     * @param branch
     */
    deleteItem(branch: string) {
        if (!branch) {
            this.processError("Unable to delete Item, branch missing", "DATABASE.ERROR_DELETE");
            return Promise.resolve(false);
        }
        return this.deleteObject(`/${branch}`);
    }

    /**
     * Generic error processor for database errors
     * @param error
     * @param translationText
     */
    processError(error: any = {'code': 'UNKNOWN'}, translationText: string = 'DATABASE.ERROR_GET') {
        this.config.debugLog("Processing database error with error code " + error.code);

        switch (error.code) {
            case 'PERMISSION_DENIED':
                translationText = "DATABASE.PERMISSION_DENIED";
                break;
            default:
        }
        this.config.processError(error, translationText, true);
    }

    /**
     * Generic DB handler with logging and error collection to reuse
     * Wrap the subscription in a subscription for better control
     *
     * @param {string} path
     * @param {boolean} onlyOnce
     * @param addId
     * @param setId
     * @returns {Promise<any>}
     */
    protected getObject(path: string, onlyOnce: boolean = false, addId: boolean = false, setId?: string): Observable<any> {
        this.config.debugLog(`"Getting Object on ${path}"`);
        return new Observable<any>((objectObserver) => {
            let returnObject: Observable<any>;
            if (!addId) {
                returnObject = this.afDB.object(path).valueChanges();
            }
            else {
                returnObject = this.afDB.object(path).snapshotChanges().map(
                    (action) => {
                        //Only add id when something is found.
                        if (action.payload.val()) {
                            let id = "";
                            if (!setId) {
                                id = action.payload.key;
                            }
                            else {
                                id = setId;
                            }
                            const data = {id, ...action.payload.val()};
                            return data;
                        }
                        else return action.payload.val();
                    }
                );
            }

            if (onlyOnce) {
                returnObject = returnObject.take(1);
            }

            const objectSubscription = returnObject.subscribe((value) => {
                //console.log("received",value);
                objectObserver.next(value);
            }, (error) => {
                this.processError(error, 'DATABASE.ERROR_GET');
            });

            this.activeSubscriptions.push(objectSubscription);

            //Teardown logic
            return () => {
                this.config.debugLog(`"Unsubscribed to Object on branch ${path}"`);
                objectSubscription.unsubscribe();
            };
        });
    }

    /**
     * Converts Objects in the form of { id: true, id2: true } to array [ id, id2 ] to use in views
     *
     * @param {string} path
     * @param {boolean} onlyOnce
     * @param sort
     * @param startAt
     * @param raw
     * @param endAt
     * @returns {Observable<any>}
     */
    protected getReferencesOfIds(path: string, onlyOnce: boolean = false, sort: string = 'asc', startAt: string = null, raw = false, endAt: number | string = null) {
        this.config.debugLog(`"Getting References of Ids on ${path} ${startAt} ${sort} ${endAt}"`);
        return new Observable<any>((objectObserver) => {
            //OLD let returnObject = this.afDB.list(path).valueChanges();
            let returnObject;
            if (startAt != null) {
                returnObject = this.afDB.list(path, ref => ref.orderByValue().startAt(startAt)).snapshotChanges();
            }
            else if (endAt != null) {
                returnObject = this.afDB.list(path, ref => ref.orderByValue().endAt(endAt)).snapshotChanges();
            }
            else {
                returnObject = this.afDB.list(path, ref => ref.orderByValue()).snapshotChanges();
            }

            if (onlyOnce) {
                returnObject = returnObject.take(1);
            }

            const objectSubscription = returnObject.subscribe(
                (value) => {
                    if (raw) {
                        return objectObserver.next(value);
                    }
                    else {
                        let idsArray = [];
                        //console.log("received",value);
                        value.forEach((arrayEntry) => {
                            idsArray.push(arrayEntry['key']);
                        });

                        if (sort == 'desc') {
                            idsArray.reverse();
                        }

                        objectObserver.next(idsArray);
                    }
                },
                (error) => {
                    this.processError(error, 'DATABASE.ERROR_GET');
                }
            );

            this.activeSubscriptions.push(objectSubscription);

            //Teardown logic
            return () => {
                this.config.debugLog(`"Unsubscribed to References of Ids on branch ${path}"`);
                objectSubscription.unsubscribe();
            };
        });
    }

    /**
     * DB helper for object updates
     *
     * @param {string} path
     * @param object
     * @returns {Promise<any>}
     */
    protected updateObject(path: string, object: any): Promise<any> {
        //delete id
        if (object.id) {
            object = Object.assign({}, object); //make new item not a reference to it,
            // otherwise parent id will be deleted causing some strange errors
            delete object.id;
        }

        this.config.debugLog(`"Updating Object on ${path}"`, object);
        return new Promise<any>((resolve, reject) => {
            if (typeof object === 'object') {
                this.afDB.object(path).update(object)
                    .then(
                        (value) => {
                            resolve(true);
                        }
                    )
                    .catch(
                        (error) => {
                            this.processError(error, 'DATABASE.ERROR_UPDATE');
                            resolve(false);
                        }
                    );
            }
            else {
                this.afDB.object(path).set(object)
                    .then(
                        (value) => {
                            resolve(true);
                        }
                    )
                    .catch(
                        (error) => {
                            this.processError(error, 'DATABASE.ERROR_UPDATE');
                            resolve(false);
                        }
                    );
            }

        });
    }

    /**
     * DB helper for object updates
     *
     * @param {string} path
     * @param object
     * @returns {Promise<any>}
     */
    protected pushObject(path: string, object: any): Promise<any> {
        this.config.debugLog(`"Pushing Object on ${path}"`, object);
        return new Promise<any>((resolve, reject) => {
            Promise.resolve(this.afDB.list(path).push(object))
                .then(
                    (ref) => {
                        resolve(ref.key); //Pushed Key
                    }
                )
                .catch(
                    (error) => {
                        this.processError(error, 'DATABASE.ERROR_ADD');
                        resolve(false);
                    }
                );
        });
    }

    /**
     * Delete an object from the database
     *
     * @param {string} path
     * @returns {Promise<any>}
     */
    protected deleteObject(path: string): Promise<any> {
        this.config.debugLog(`"Deleting Object on ${path}"`);
        return new Promise<any>((resolve, reject) => {
            this.afDB.object(path).remove().then(() => {
                return resolve(true);
            }).catch((error) => {
                this.processError(error, 'DATABASE.ERROR_DELETE');
                return resolve(false);
            });
        });
    }

    /**
     * Destroy all running subscriptions.
     * Especially usefull on logout so the user doesn't try to get an unauthorised error.
     *
     * @returns {Promise<any>}
     */
    destroyAllSubscriptions() {
        this.config.debugLog("Destroying all active subscriptions (observers) from db.");
        return new Promise((resolve, reject) => {
            if ((this.activeSubscriptions) && (this.activeSubscriptions.length > 0)) {
                for (let activeSubscriptions of this.activeSubscriptions) {
                    activeSubscriptions.unsubscribe();
                }
                resolve(true);
            }
            else resolve(true);
        });
    }

    //Common functions

    /**
     * Return Database Timestamp
     *
     * @returns {Object}
     */
    static timestamp() {
        return firebase.database.ServerValue.TIMESTAMP;
    }

    /**
     * transform object { id: name }, ... to [[name, id], [name, id2]] so we can sort
     */
    static objectToArray(initialObject) {
        let finalArray = [];
        for (let key in initialObject) {
            // if parameter is rue false, use the id/index to sort on
            if (typeof(initialObject[key]) === "boolean") {
                finalArray.push([key, key]);
            }
            else {
                finalArray.push([initialObject[key].toString(), key.toString()]);
            }
        }
        return finalArray;
    }


    /**
     * Proccess returning promises (or promise ALL) from database operation to check if it was succesfull or not
     *
     * @param promiseReturnValue
     * @returns {any}
     */
    static processSuccess(promiseReturnValue: any) {
        if (Array.isArray(promiseReturnValue)) {
            for (let i = 0; i < promiseReturnValue.length; i++) {
                if (promiseReturnValue[i] === false) {
                    return false;
                }
            }
            return true;
        }
        else if ((promiseReturnValue === false) ||
            (promiseReturnValue === true)) {
            return promiseReturnValue;
        }
        else {
            //No array and no boolean, so no idea what was return, so return false
            return false;
        }
    }
}
