import {Injectable} from '@angular/core';

import {ConfigProvider} from "../";
import {AngularFireStorage} from "@angular/fire/storage";

/* Storage functionality based on firebase Storage
 * Inspiration from
 * https://github.com/angular/@angular/fire/issues/946#issuecomment-299639239
 */

@Injectable()
export class StorageProvider {

    constructor(private config: ConfigProvider,
                private storage: AngularFireStorage) {
    }

    /**
     * AddImage helper function to upload a image to storage, returns an Upload task
     *
     * @param branch
     * @param {string} base64String
     * @param metaData
     */
    addString(branch, base64String: string, metaData: Object = {contentType: 'image/png'}) {
        this.config.debugLog("Adding new Image to Storage: " + branch);
        return this.storage.ref(branch).putString(base64String, 'data_url', metaData);
    }

    /**
     * Add Import csv page
     *
     * @param branch
     * @param selectedFile
     * @param metaData
     */
    addFile(branch, selectedFile: any) {
        this.config.debugLog("Adding new Import Page to Storage: " + branch);
        return this.storage.upload(branch, selectedFile);
    }

    /**
     * Get download URL, using ref, instead of the now Deprecated uploadTask.
     *
     * @param storagePath
     * @returns {Observable<any>}
     */
    getDownloadUrl(storagePath) {
        return this.storage.ref(storagePath).getDownloadURL();
    }
}
