import {Injectable} from '@angular/core';
import {Firebase} from '@ionic-native/firebase/ngx';
import {GoogleAnalytics} from '@ionic-native/google-analytics/ngx';
import {Platform} from '@ionic/angular';
import {CoreConfigProvider} from "./coreConfig";


/*
 Core Analytics (core so it is possible to extend the class.)

 Known bug in ionic: https://github.com/danwilson/google-analytics-plugin/issues/422
 */


@Injectable({providedIn: 'root'})
export class CoreAnalyticsProvider {
    private _ready_promise: Promise<boolean>;

    constructor(public googleAnalytics: GoogleAnalytics,
                public firebase: Firebase,
                public config: CoreConfigProvider,
                public platform: Platform) {

        this._ready_promise = this.loadAnalytics(this.config.config.analyticTrackerId);
    }

    /**
     * Initial load of the analytics
     *
     * @param analyticTrackerId
     * @returns {Promise<boolean>}
     */
    loadAnalytics(analyticTrackerId: string) {
        return new Promise<boolean>((resolve, reject) => {
            this.platform.ready().then(() => {
                if (this.platform.is("cordova")) {
                    this.googleAnalytics.startTrackerWithId(analyticTrackerId)
                        .then(() => {
                            // this.googleAnalytics.trackView('started');
                            this.config.debugLog("Google Analytics initialised");

                            //*** BUG 1second otherwise home view isn't send.
                            setTimeout(() => {
                                resolve(true);
                            }, 1000);
                            // resolve(true);

                        })
                        .catch((error) => {
                            this.config.processError(error, 'Error starting GoogleAnalytics');
                            reject(false);
                        });
                }
                else {
                    //Todo load Analytics in browser
                    resolve(false);
                }
            });
        });
    }

    /**
     * Promise with an anlytics trace tied to it to report the proces time in Analytics
     * @param {Promise<any>} promise
     * @returns {Promise<any>}
     */
    promiseWithTrace(promise: Promise<any>): Promise<any> {
        return new Promise<boolean>((resolve, reject) => {
            promise.then(
                (result) => {
                    resolve(result);
                }
            ).catch(
                (error) => {
                    reject(error);
                }
            )
        });
    }

    /**
     * ready helper when Analytics isn't ready yet.
     *
     * @returns {Promise<boolean>}
     */
    ready() {
        return this._ready_promise;
    }

    /**
     * Log an event using Analytics
     *
     * @returns {Promise<any>}
     * @param category
     * @param action
     * @param label
     * @param value
     */
    logEvent(category: string, action: any = null, label: any = null, value: any = null): Promise<any> {
        return new Promise<boolean>((resolve, reject) => {

            this.ready().then(() => {
                let promiseArray = [];
                if (this.platform.is("cordova")) {
                    promiseArray.push(this.googleAnalytics.trackEvent(category, action, label, value));
                    promiseArray.push(this.firebase.logEvent(category, {
                        'label': label,
                        'action': action,
                        'value': value
                    }));
                }
                //window.FirebasePlugin.logEvent("select_content", {content_type: "page_view", item_id: "home"});
                Promise.all(promiseArray).then(
                    () => {
                        this.config.debugLog("Log'd Event", category, action, label, value);
                        resolve();
                    }).catch((error) => {
                    this.config.debugLog("Error Logging Event", error);
                    resolve();
                })

            });

        });
    }

    /**
     * Set the name of the current screen in Analytics
     *
     * @returns {Promise<any>}
     * @param title
     */
    setScreenName(title: string) {
        return new Promise<boolean>((resolve, reject) => {
            this.ready().then(() => {
                let promiseArray = [];
                if (this.platform.is("cordova")) {
                    promiseArray.push(this.googleAnalytics.trackView(title));
                    // firebase is only available in cordova (not in Angular Fire)
                    promiseArray.push(this.firebase.setScreenName(title));
                }
                Promise.all(promiseArray).then(() => {
                    this.config.debugLog("Set'd ScreenName to: " + title);
                    resolve();
                }).catch((error) => {
                    this.config.debugLog("Error setting screenName", error);
                    resolve();
                })
            })
        });
    }

    /**
     * Log Error to crash reporting
     *
     * @param message
     * @returns {Promise<any>}
     */
    logError(message: string): Promise<any> {
        return Promise.resolve();
        //return this.firebase.logError(message);
    }

    /**
     * Set a user id for use in Analytics
     *
     * @returns {Promise<any>}
     * @param userId
     */
    setUserId(userId: string): Promise<any> {
        return new Promise<boolean>((resolve, reject) => {
            let promiseArray = [];
            if (this.platform.is("cordova")) {
                promiseArray.push(this.googleAnalytics.setUserId(userId));
                promiseArray.push(this.firebase.setUserId(userId));
            }
            Promise.all(promiseArray)
                .then(
                    () => {
                        this.config.debugLog("Setting User Id for Analytics to " + userId);
                        resolve();
                    }).catch(
                (error) => {
                    this.config.processError(error, "Error setting User ID for Analytics", error);
                    resolve();
                }
            );
        });
    }

    /**
     * Set a user property for use in Analytics
     *
     * @param user
     * @param value
     * @returns {Promise<any>}
     */
    setUserProperty(user: string, value: string): Promise<any> {
        if (this.platform.is("cordova")) {
            return Promise.resolve(
                this.firebase.setUserProperty(name, value),
            );
        }
        else Promise.resolve(false);
    }

    /**
     *  track the timing of a given category
     */
    trackTiming(category: string, intervalInMilliseconds, variable, label) {
        return this.googleAnalytics.trackTiming(category, intervalInMilliseconds, variable, label);
    }
}
