import {Component} from '@angular/core';

import {Platform, Config, MenuController, NavController} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {TranslateService} from '@ngx-translate/core';
import {ConfigProvider} from '..';
import {AuthenticationProvider} from '../services/authentication';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    public appPages = [
        /*{
            title: 'Home',
            url: '/home',
            icon: 'home'
        },
        {
            title: 'List',
            url: '/list',
            icon: 'list'
        },*/
        {
            title: 'Slapende leden',
            url: '/nonAttendingMembers',
            icon: 'people'
        }
    ];

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private translate: TranslateService,
        private config: ConfigProvider,
        private ionicConfig: Config,
        public auth: AuthenticationProvider,
        private menu: MenuController,
        private nav: NavController,

    ) {
        // On init close the menu otherwise, it is briefly shown ot non admin users.
        this.menu.enable(false, 'main');

        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();

            //Load the translation service
            this.initTranslate();

            //Listen page authentication state changes and change page accordingly
            this.auth.login.subscribe((user) => {
                //this.nav.navigateRoot('list');
                this.nav.navigateRoot('nonAttendingMembers');

                //Only enable menu on admin.
                if (this.config.authUserRights >= this.config.rightsAdmin) {

                    this.menu.enable(true, 'main');
                    this.config.debugLog('Enabled menu as user is an admin.');
                } else {
                    this.menu.enable(false, 'main');
                    this.config.debugLog('Disabled menu as user is not an admin.');
                }
            });
            this.auth.logout.subscribe(() => {
                this.nav.navigateRoot('auth');

                //Disable menu as it is poor design in a login flow.
                this.menu.enable(false, 'main');
            });
        });
    }

    initTranslate() {
        //Load default language from app (Todo load from local storage)
        // Set the default language for translation strings, and the current language.
        this.translate.setDefaultLang(this.config.config.defaultLanguage);

        if (this.translate.getBrowserLang() !== undefined) {
            this.translate.use(this.translate.getBrowserLang());
        } else {
            //this.translate.use('en');  //Not needed as the default lang is already set
        }

        this.translate.get(['BACK_BUTTON_TEXT']).subscribe(values => {
            this.ionicConfig.set('backButtonText', values.BACK_BUTTON_TEXT);
        });
    }
}
