import {Injectable} from '@angular/core';
import {ConfigProvider} from "../";
import {Firebase} from '@ionic-native/firebase/ngx';
import {Events, Platform} from "@ionic/angular";
import {DatabaseProvider} from "./database";
import {UserProvider} from './user.service';

/*
  Wrapper class for push notifications
*/
@Injectable()
export class NotificationProvider {

    constructor(private config: ConfigProvider,
                private firebase: Firebase,
                private platform: Platform,
                private database: DatabaseProvider,
                private user: UserProvider,
                private events: Events) {

        // When app is opened from notification
        this.platform.ready().then(() => {
            if (this.platform.is("cordova")) {
                this.firebase.onNotificationOpen().subscribe((result) => {
                    this.config.debugLog('Opened notifcation with parameters', result);
                    if (result.tap === true) {
                        //Opened by clicking on notification
                        this.events.publish('notification:open', result);
                    }
                    else {
                        //App was already open

                        //Show toast with new recommendation
                        this.config.showToast({
                            type: 'success',
                            text: 'RECOMMENDATION.NEW',
                            translationParams: {value: result.body}
                        });
                    }
                });
            }
        });

        //Change badge when new unread recommendations
        this.events.subscribe('recommendations:changes', (amountOfRecommendations) => {
            this.setBadgeNumber(amountOfRecommendations);
        });
    }

    /**
     * Receive device token so we can later send notifications.
     */
    async getToken() {
        if (this.platform.is("cordova")) {
            if (this.platform.is('ios')) {
                //Don't use await here, as it will 'freeze' ios on 2nd login.
                this.grantPermission();
                //await this.grantPermission();
            }

            this.firebase.getToken()
                .then(token => {
                        if (token) {
                            this.config.debugLog(`Received token ${token}`);
                            this.storeToken(token);
                        }
                        else {
                            this.config.debugLog(`Received token was empty`);
                        }
                    }
                )
                .catch(error => {
                        this.config.debugLog('Error getting token', error);
                        if (error.code === 'messaging/permission-default') {
                            this.config.processError(error, "NOTIFICATIONS.ERROR_PERMISSION_DEFAULT", true);
                        } else if (error.code === 'messaging/notifications-blocked') {
                            this.config.processError(error, "NOTIFICATIONS.ERROR_NOTIFICATIONS_BLOCKED", true);
                        }
                    }
                );

            this.firebase.onTokenRefresh()
                .subscribe(
                    (token: string) => {
                        this.config.debugLog(`Received a new token ${token}`);
                        this.storeToken(token);
                    });
        }
    }

    /**
     * Store token in the database for future notifications
     *
     * @param {string} token
     */
    storeToken(token: string) {
        if ((token) && (this.config.currentAuthenticatedUser.id)) {
            this.database.addUserDeviceToken(this.config.currentAuthenticatedUser.id, token);
        }
    }

    /**
     * Grants permission
     * ONLY WORKS ON IOS
     *
     * @returns {Promise<{isEnabled: boolean}>}
     */
    grantPermission() {
        if (this.platform.is("cordova")) {
            return this.firebase.grantPermission();
        }
        else return Promise.resolve(false);
    }

    /**
     * Does the user has given permission for notifications?
     *
     * @returns {Promise<{isEnabled: boolean}>}
     */
    hasPermission() {
        if (this.platform.is("cordova")) {
            return this.firebase.hasPermission();
        }
        else return Promise.resolve(false);
    }

    /**
     * Set the badge number of the app icon notifications
     *
     * @param {number} badgeNumber
     * @returns {Promise<any>}
     */
    setBadgeNumber(badgeNumber: number) {
        if (this.platform.is("cordova")) {
            return this.firebase.setBadgeNumber(badgeNumber);
        }
        else return Promise.resolve(false);
    }

    /**
     * Subscribe to a particular topic to receive notifications.
     *
     * @param {string} topic
     * @returns {any}
     */
    subscribeTopic(topic: string) {
        if (this.platform.is("cordova")) {
            return this.firebase.subscribe(topic);
        }
        else return Promise.resolve(false);
    }

    /**
     * Unsubscribe to a particular topic
     *
     * @param {string} topic
     * @returns {any}
     */
    unsubscribeTopic(topic: string) {
        if (this.platform.is("cordova")) {
            return this.firebase.unsubscribe(topic);
        }
        else return Promise.resolve(false);
    }
}
