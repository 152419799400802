import {Injectable} from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import {ConfigProvider} from "../";
import {TranslateService} from "@ngx-translate/core";
import {AnalyticsProvider} from "./analytics";

declare var window;

/*
  Generated class for the ShareProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class ShareProvider {

    constructor(public config: ConfigProvider,
                public translate: TranslateService,
                public analytics: AnalyticsProvider,
                public iab: InAppBrowser) {
    }


    /**
     * Call the number
     */
    call(phone) {
        if (phone) {
            this.analytics.logEvent("clickedPhone", phone);
            window.location = "tel:" + encodeURIComponent(phone);
        }
    }

    /**
     * Mail
     */
    mail(mail: string) {
        if (mail) {
            this.analytics.logEvent("clickedMail", mail);
            window.location = "mailto:" + mail;
        }
    }

    /**
     * Open googlemaps via the browser
     */
    openAddress(address: string) {
        if (address) {
            this.analytics.logEvent("clickedAddress", address);
            this.openUrl("http://maps.google.com/?q=" + address);
        }
    }

    /**
     * Open the website
     */
    website(site: string) {
        if (site) {
            this.analytics.logEvent("clickedWebsite", site);
            this.openUrl(site);
        }
    }

    /**
     * Open LinkedIn profile page
     */
    linkedin(linkedin: string) {
        if (linkedin) {
            this.analytics.logEvent("clickedWebsite", linkedin);
            this.openUrl(linkedin);
        }
    }

    /**
     * Open facebook page
     */
    facebook(facebook: string) {
        if (facebook) {
            this.analytics.logEvent("clickedFacebook", facebook);
            this.openUrl(facebook);
        }
    }

    /**
     * Open a video link
     *
     * @param {string} video
     */
    video(video: string) {
        if (video) {
            this.analytics.logEvent("clickedVideo", video);
            this.openUrl(video);
        }

    }

    /**
     * Show the url in the in app browser
     */
    openUrl(url: string = null) {
        if (url) {
            if ((url.substr(0, 4) !== "http") && (url.substr(0, 4) !== "data")) {
                url = "http://" + url;
            }
            const browser = this.iab.create(url, '_blank');
            this.analytics.logEvent("share", "url", url, "success");
            //return browser;
        }
    }
}
