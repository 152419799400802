import {Injectable} from '@angular/core';
import {LoadingController, Platform} from "@ionic/angular";
import {AlertController, ToastController, NavController} from '@ionic/angular';
import {TranslateService} from "@ngx-translate/core";

import {Config} from "../";
import {CoreConfigProvider} from "../../core/services/coreConfig";  //Todo why doesn't this work in custom barrel?

@Injectable()
export class ConfigProvider extends CoreConfigProvider {
    public rightsUnknown = -1;
    //public rightsBlocked = -1;
    public rightsInactive = 0;
    public rightsUser = 25;
    public rightsAdmin = 75;

    public defaultConfig = {
        authenticationPersistenceState: 'local', //see https://firebase.google.com/docs/reference/js/firebase.auth.Auth#.Persistence
        authenticationMethods: ['mail'], //,'phone','facebook','google','twitter','github'
        debug: 1,
        defaultLanguage: 'nl',
        toastDuration: 3,
        version: '0.0.0',
        dbBranchPrefix: 'v0',
        dbBranchMain: null
    } as Config;

    public config: Config;


    constructor(public alertCtrl: AlertController,
                public translate: TranslateService,
                public loadingCtrl: LoadingController,
                public toastCtrl: ToastController,
                public platform: Platform,
                public nav: NavController ) {

        super(alertCtrl, translate, loadingCtrl, toastCtrl, nav);

        this.loadConfig();

        //Properties in the target object will be overwritten by properties in the sources if they have the same key.
    }

    /**
     * Translate the rights number to readable text (with translation
     *
     * @param {number} rights
     * @returns {Promise<boolean>}
     */
    rightsToText(rights: number = this.rightsUnknown) {
        let textToTranslate: string = 'USER.ROLE_UNKNOWN'; //Default

        if (rights >= this.rightsAdmin) {
            textToTranslate = 'USER.ROLE_MANAGEMENT';
        }
        else if (rights >= this.rightsUser) {
            textToTranslate = 'USER.ROLE_USER';
        }

        else if (rights >= this.rightsInactive) {
            textToTranslate = 'USER.ROLE_INACTIVE';
        }

        return textToTranslate;
    }

}
