import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CanActivateGuard} from '../services/canActivate.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        loadChildren: './home/home.module#HomePageModule'
    },
    {
        path: 'nonAttendingMembers',
        loadChildren: './nonAttendingMembers/nonAttendingMembers.module#NonAttendingMembersModule',
        canActivate: [CanActivateGuard]
    },
    {
        path: 'list',
        loadChildren: './list/list.module#ListPageModule'
    },
    {path: 'auth', loadChildren: './Auth/auth.module#AuthPageModule'},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
